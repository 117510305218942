import BookFreeClassBtn from '@components/common/book-free-class-btn'
import MsgTrans from '@components/common/i18n/message-trans'
import { FastlyImage } from '@components/common/image'
import { GEO_CONTENT, LANGUAGES, LanguageType } from '@lib/constants/geo-lp'
import { msgT } from '@lib/utils/i18n'

const IMAGE_PATH = '/images/geo-landing-page/why-codingal/'
const { ENGLISH } = LANGUAGES

type Props = {
  language?: LanguageType
  isRightToLeftPage?: boolean
  cta?: string
}
const WhyCodingal = ({ language = ENGLISH, isRightToLeftPage, cta }: Props) => {
  const {
    whyCodingalFold: { heading, contents },
    bookTrialClassCTA,
  } = GEO_CONTENT[language]
  return (
    <div className="relative mt-16 mb-10">
      <div className="container px-4 mx-auto">
        <h2 className="my-20 text-3xl text-center font-700 lg:text-4xl text-grey-900">
          {!isRightToLeftPage && <MsgTrans msg={heading.normalText} />}{' '}
          <span className="underlined-text">
            <MsgTrans msg={heading.underlinedText} />
          </span>{' '}
          {isRightToLeftPage && <MsgTrans msg={heading.normalText} />}
        </h2>
        <div className="flex flex-col gap-16 md:flex-row md:gap-20 md:px-16">
          {contents.map((content) => (
            <div key={content.imgLabel} className="flex flex-col">
              <div className="flex justify-center">
                <FastlyImage
                  src={`${IMAGE_PATH}${content.imgLabel}.webp`}
                  className="rounded-lg"
                  width={320}
                  height={256}
                  alt={`${msgT(content.coloredHeading)}`}
                />
              </div>

              <div className="md:flex md:flex-col md:justify-center">
                <div className="flex flex-col items-center justify-center pt-6 pb-3">
                  <p className="text-2xl leading-tight text-center font-700 text-grey-900">
                    <MsgTrans msg={content.heading} /> <br />
                    <span className="text-orange">
                      <MsgTrans msg={content.coloredHeading} />
                    </span>
                  </p>
                </div>
                <p className="text-center font-400 text-grey-900">
                  <MsgTrans msg={content.subHeading} />
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-8 md:mt-12">
          <BookFreeClassBtn
            shouldScrollToTop
            ctaText={cta || bookTrialClassCTA}
          />
        </div>
      </div>
    </div>
  )
}

export default WhyCodingal
