import BookFreeClassBtn from '@components/common/book-free-class-btn'
import MsgTrans from '@components/common/i18n/message-trans'
import { FastlyImage } from '@components/common/image'
import {
  GEO_CONTENT,
  IMAGE_PATH,
  LANGUAGES,
  LanguageType,
  TARGATED_REGIONS,
} from '@lib/constants/geo-lp'
import { UnionizeObjValues } from '@lib/types/common'
import classNames from 'classnames'

const { AFRICA, AMERICA } = TARGATED_REGIONS
const { ENGLISH } = LANGUAGES

type Props = {
  region?: UnionizeObjValues<typeof TARGATED_REGIONS>
  language?: LanguageType
  isRightToLeftPage?: boolean
  customSubHeading?: string
  cta?: string
}

const Benefits = ({
  region = AMERICA,
  language = ENGLISH,
  isRightToLeftPage,
  customSubHeading,
  cta,
}: Props) => {
  const {
    learnToCodeFold: { heading, subHeading },
    bookTrialClassCTA,
  } = GEO_CONTENT[language]
  return (
    <div className="flex-col items-center mt-32 space-y-10 text-center lg:flex lg:space-y-6">
      <h2 className="text-3xl text-grey font-700 lg:text-4xl">
        {isRightToLeftPage && (
          <>
            <span className="underlined-text">
              <MsgTrans msg={heading.underlinedText} />
            </span>{' '}
            <MsgTrans msg={heading.normalText} />
          </>
        )}
        {!isRightToLeftPage && (
          <>
            <MsgTrans msg={heading.normalText} />{' '}
            <span className="underlined-text">
              <MsgTrans msg={heading.underlinedText} />
            </span>
          </>
        )}
      </h2>
      {customSubHeading && (
        <p className="max-w-2xl px-2 text-xl text-grey">{customSubHeading}</p>
      )}
      {!customSubHeading && (
        <p className="max-w-2xl px-2 text-xl text-grey">
          <MsgTrans msg={subHeading} />
        </p>
      )}

      <div className="relative w-full">
        <div
          className={classNames('relative z-10 mx-auto w-64', {
            '-mt-8 lg:-mt-4 lg:w-128': region === AMERICA,
            '-mt-20 lg:-mt-10 lg:w-108': region === AFRICA,
          })}
        >
          <FastlyImage
            src={`${IMAGE_PATH}/${region}n-students.png`}
            alt="Student winning"
            width={region === AMERICA ? 512 : 432}
            height={region === AMERICA ? 342 : 368}
          />
        </div>
        <div className="absolute top-0 w-full h-40 lg:h-96">
          <FastlyImage
            src={`${IMAGE_PATH}/bg-stripes.png`}
            alt="Student winning"
            layout="fill"
          />
        </div>
        <div className="flex justify-center px-2 mx-auto mt-5 lg:mt-20">
          <BookFreeClassBtn
            shouldScrollToTop
            ctaText={cta || bookTrialClassCTA}
          />
        </div>
      </div>
    </div>
  )
}

export default Benefits
