import MsgTrans from '@components/common/i18n/message-trans'
import { GEO_CONTENT, LANGUAGES, LanguageType } from '@lib/constants/geo-lp'
import { currentYear } from '@lib/utils/date-native'
import { Mail } from 'react-feather'

const { ENGLISH } = LANGUAGES

type Props = {
  language?: LanguageType
}
const Footer = ({ language = ENGLISH }: Props) => {
  const { footer } = GEO_CONTENT[language]
  return (
    <div className="container mx-auto lg:mt-20">
      <div className="flex flex-col-reverse items-center justify-between my-2 md:flex-row md:my-6">
        <div className="pt-6 text-grey-800 md:pt-0">
          {footer.copyRights ? (
            <MsgTrans msg={footer.copyRights} />
          ) : (
            `©️ ${currentYear} Codingal. All rights reserved.`
          )}
        </div>
        <div className="flex flex-col px-12 py-6 mt-2 space-y-5 bg-white border rounded-lg shadow-lg lg:flex-row lg:justify-between lg:space-x-12 lg:space-y-0 border-grey-300 lg:border-none lg:shadow-none lg:mt-0">
          <div className="flex flex-col items-center space-y-4 text-center lg:text-left lg:items-end font-600">
            <p className="text-orange-600 lg:ml-6">
              <MsgTrans msg={footer.supprotHeading} />
            </p>
            <div className="flex items-center">
              <span className="flex items-end justify-center w-5 h-5 rounded-full bg-grey-200">
                <Mail className="text-orange" size={18} />
              </span>
              <a
                className="ml-1 text-orange lg:text-xl"
                href="mailto:support@codingal.com"
              >
                support@codingal.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
